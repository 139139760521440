<template>
  <div class="dashboard_ordenes">
    <v-app>
      <v-card>
        <v-card-title>
          Ordenes en el sistema
          <v-spacer></v-spacer>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class @click="showSelect = !showSelect" text icon color="blue">
                <v-icon>mdi-{{showSelect ? 'table-off' : 'table'}}</v-icon>
              </v-btn>
            </template>
            <span>{{showSelect ? 'Desactivar':'Activar'}} modo avanzado</span>
          </v-tooltip> -->
          <v-tooltip v-if="showSelect && selected.length > 0" bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class @click="addSelected" text icon color="blue">
                <v-icon>mdi-table-plus</v-icon>
              </v-btn>
            </template>
            <span> Agregar pedido{{selected.length > 1 ? 's':''}} a la cola</span>
          </v-tooltip>
          <v-tooltip v-if="added.length > 0 && showSelect" bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class @click="openDialogCambiarEstadoMasivo" text icon color="blue">
                <v-icon>icon-action_updatestate</v-icon>
              </v-btn>
            </template>
            <span>Cambiar estado a cola de Pedidos</span>
          </v-tooltip>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class @click="$emit('open-exportar-ordenes-dialog')" text icon color="green">
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Exportar a Excel</span>
          </v-tooltip> -->
          <v-btn class @click="fetchDataPaginated" text icon color="blue">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-card outlined tile elevation="1">
            <v-card-text style="padding-bottom: 0px;">
              <div class="d-flex justify-space-between mb-4">
                <v-text-field
                  v-model="search"
                  clearable
                  label="Buscar"
                  hide-details
                  dense
                  solo
                  @change="fetchDataPaginated"
                  class="mr-2"
                ></v-text-field>
                <v-btn class="btn-starkoms-primary" dark style="min-width: 138px;" @click="fetchDataPaginated" >Buscar</v-btn>
              </div>
              <div class="d-flex justify-space-between">
                <div class="d-flex flex-column">
                  <span class="text-h6 font-weight-regular black--text"><i class="icon-filter_calendar"></i> Filtro por fecha</span>
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    style="border-bottom: 1px solid #000;"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="limpiarRango"
                        v-model="dateRangeText"
                        placeholder="Todos"
                        solo
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker id="datepicker-starkoms" @input="formatearRango" range v-model="dates"></v-date-picker>
                  </v-menu>
                </div>
                <div class="d-flex flex-column">
                  <span class="text-h6 font-weight-regular black--text"><i class="icon-filter_states"></i> Filtro por estado</span>
                  <v-select
                    class="ml-2 mr-2"
                    label="Filtro por estado"
                    :items="orderStateList"
                    v-model="selectOrderState"
                    item-text="name"
                    @input="fetchDataPaginated"
                    solo
                  ></v-select>
                </div>
                <div class="d-flex flex-column">
                  <span class="text-h6 font-weight-regular black--text"><i class="icon-filter_types"></i> Filtro por tipo</span>
                  <v-select
                    class="ml-2 mr-2"
                    label="Filtro por tipo"
                    :items="orderTypeList"
                    v-model="selectTypeList"
                    @input="fetchDataPaginated"
                    solo
                  ></v-select>
                </div>
                <div class="d-flex flex-column">
                  <span class="text-h6 font-weight-regular black--text"><i class="icon-warehouses"></i> Filtro por bodega</span>
                  <v-select
                    class="ml-2 mr-2"
                    label="Filtro por bodega"
                    :items="warehousesList"
                    v-model="selectWarehouses"
                    item-text="name"
                    @input="fetchDataPaginated"
                    solo
                  ></v-select>
                </div>
                <div class="d-flex flex-column">
                  <span class="text-h6 font-weight-regular black--text"><i class="icon-warehouses"></i> Filtro por Store</span>
                  <v-select
                    class="ml-2 mr-2"
                    label="Filtro por store"
                    :items="storesList"
                    v-model="selectStores"
                    item-text="name"
                    @input="fetchDataPaginated"
                    solo
                  ></v-select>
                </div>
                <div class="d-flex">
                  <v-btn
                    v-show="!!search || !!dateRangeText || selectOrderState != 'Todos' || selectTypeList != 'Todos' || selectWarehouses != 'Todos' || selectStores != 'Todos'"
                    @click="limpiarFiltros"
                    class="align-self-center" dark color="grey"
                  >Limpiar Filtros</v-btn>
                </div>
              </div>
              <v-row v-if="(showSelect || selected.length > 0) || added.length">
                <v-col>
                  <v-card class="d-flex">
                    <v-card-text>
                      <span class="subheading">Cola de Pedidos ({{added.length}})</span>
                      <v-chip-group column show-arrows >
                        <v-chip
                          v-for="(orderSelected,index) in added"
                          :key="index"
                          close
                          @click:close="added.splice(index, 1)"
                        >
                          {{ orderSelected.order_reference }}
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="tableData"
            :single-select="false"
            :show-select="showSelect"
            v-model="selected"
            no-results-text="No hay resultados disponibles"
            no-data-text="No hay datos disponibles"
            loading-text="Cargando datos..."
            :sort-by="'id'"
            :sort-desc="true"
            class="elevation-1 datatable"
            :disable-sort="true"
            :loading="dataTableLoading"
            :disable-pagination="true"
            :hide-default-footer="true"
            :items-per-page="selectItemsPerPage"
          >
            <template v-slot:item.id="{ item }">
              <td class="text-center">

                   <v-tooltip top  v-if="item.tags != null" >
                    <template v-slot:activator="{ on }">
                      <span v-on="on" class="badge badge-circle badge-danger mr-2"><i class="fa fa-info-circle"></i></span>
                    </template>
                    <span>{{item.tags}}</span>
                  </v-tooltip>

                
                <span>{{ item.id }}</span>
              </td>
            </template>
            <template v-slot:item.order_reference="{ item }">
              <td>
                <a class="text-decoration-none" href="#" @click="$router.push({name: 'orden', params: {orden: item.id}})">{{item.order_reference}}</a>
              </td>
            </template>
            <template v-slot:item.shipping_link="{ item }">
              <td class="text-center">
                <a v-if="item.tracking_carrier" :href="'#'" @click="getShippingLink(item.tracking_carrier)" >{{ item.shipping_number }}</a>
                <span v-if="!item.tracking_carrier" >{{ item.shipping_number }}</span>
              </td>
            </template>
            <template v-slot:item.created_at="{ item }">
              <td
                class="text-center"
              >{{$moment(item.created_at).format('LLLL')}}</td>
            </template>
            <template v-slot:item.name_order_state="{ item }">
              <td class="d-flex flex-column">
                <button
                  :class="'btn btn-sm btn-'+item.colour_order_state"
                  v-text="item.name_order_state+(item.name_order_sub_state ? ' ('+item.name_order_sub_state+')' : '')"
                ></button>
              </td>
            </template>
            <template v-slot:item.acciones="{ item }">
              <td class="d-flex">
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <i
                      style="font-size: 1.5rem !important;"
                      class="icon-action_quickview mr-2"
                      v-on="on"
                      @click="verOrden(item.id)"
                    ></i>
                  </template>
                  <span>Vista previa</span>
                </v-tooltip> -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <i
                      
                      style="font-size: 1.5rem !important;"
                      class="icon-action_editorder mr-2"
                      v-on="on"
                      @click="$router.push({name: 'orden', params: {orden: item.id}})"
                    ></i>
                  </template>
                  <span>Ver/Editar orden</span>
                </v-tooltip>
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <i
                      style="font-size: 1.5rem !important;"
                      class="icon-action_updatestate mr-2"
                      v-on="on"
                      @click="cambiarEstado({order_id: item.id, store_id: item.store_id, current_state: item.current_state, current_substate_name: item.current_sub_state})"
                    ></i>
                  </template>
                  <span>Cambiar estado</span>
                </v-tooltip> -->

                <!-- <v-tooltip bottom v-if="item.type_order_state == 'DEFAULT_IN_LOGISTIC'">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      color="#8c8c8c"
                      class="mr-2"
                      v-on="on"
                      @click="generarEtiqueta(item)"
                    >flaticon2-tag</v-icon>
                  </template>
                  <span>Generar etiqueta</span>
                </v-tooltip> -->
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      style="font-size: 1.5rem !important"
                      small
                      color="#8c8c8c"
                      class="mr-2"
                      v-on="on"
                      @click="softDelete(item)"
                    >mdi-package-down</v-icon>
                  </template>
                  <span>Archivar</span>
                </v-tooltip> -->

                <v-tooltip bottom v-if="item.tracking_url"> 
                  <template v-slot:activator="{ on }">
                    <v-icon
                      style="font-size: 1.5rem !important;"
                      small
                      color="#8c8c8c"
                      class="mr-2"
                      v-on="on"
                      @click="getShippingLink(item.tracking_url)"
                    >mdi-truck-delivery</v-icon>
                  </template>
                  <span>Link Seguimiento</span>
                </v-tooltip>

              </td>
            </template>
          </v-data-table>
          <div class="d-flex justify-space-between mt-4">
            <v-select
              style="max-width: 120px"
              @input="fetchDataPaginated"
              v-model="selectItemsPerPage"
              label="Registros por página"
              :items="[10,20,50,100, 500]"
            ></v-select>
            <v-pagination
              @input="fetchDataPaginated"
              v-model="page"
              :length="totalPages"
              :total-visible="100"
              color="#e55d43"
            ></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </v-app>
    <dialog-ver-orden></dialog-ver-orden>
    <dialog-cambiar-estado-orden @success="abrirMensajeSuccess"></dialog-cambiar-estado-orden>
    <dialog-seleccionar-courier @success="abrirMensajeSuccess"></dialog-seleccionar-courier>
    <dialog-cambiar-estado-masivo @success="handleCambioEstadoMasivoSuccess"></dialog-cambiar-estado-masivo>
    <exportar-ordenes></exportar-ordenes>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import DialogVerOrden from "@/views/oms/Ordenes/DialogVerOrden";
import DialogCambiarEstadoOrden from "@/views/oms/Ordenes/DialogCambiarEstadoOrden";
import DialogSeleccionarCourier from "@/views/oms/Ordenes/DialogSeleccionarCourier";
import format from "date-fns/format";
import DialogCambiarEstadoMasivo from '@/views/oms/Ordenes/DialogCambiarEstadoMasivo';
import ExportarOrdenes from '@/views/oms/Ordenes/ExportarOrdenes';
export default {
  components: {
    DialogVerOrden,
    DialogCambiarEstadoOrden,
    DialogSeleccionarCourier,
    DialogCambiarEstadoMasivo,
    ExportarOrdenes
  },
  data() {
    return {
      showSelect: false,
      added: [],
      selectItemsPerPage: 100,
      dataTableLoading: false,
      page: 1,
      totalPages: 1,
      itemsLength: 0,
      search: "",
      selected: [],
      dateFormat: "D MMM",
      dateOne: "",
      dateTwo: "",
      tableData: [],
      orderStateList: [],
      warehousesList: [],
      storesList: [],
      /*orderStateList: [
                'Todos',
                'Ingresado',
                'Generando Etiqueta',
                'Listo para Enviar',
                'Generando Cobro',
                'Cobrado',
                'Generando Factura',
                'Facturado',
                'Entregado a Courier',
                'En tránsito',
                'Entregado'
            ],*/
      orderTypeList: ["Todos", "OD", "OA", "OR", "PS"],
      selectOrderState: "Todos",
      selectTypeList: "Todos",
      selectWarehouses: "Todos",
      selectStores: "Todos",
      superTableData: [],
      dates: [],
      menu1: false,
      dateRangeText: "",
      api_url: process.env.VUE_APP_API_URL,
    };
  },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Ordenes (Store Group)", route: "/#/ordenes-storegroup" },
        ]);
        this.fetchOrdersStates();
        this.fetchWarehouses();
        this.fetchDataPaginated();
    
  },
  methods: {
    addSelected(){
      if(this.selected.length){
        if((this.selected.length + this.added.length) > 50){
          this.$bvToast.toast("La cola de pedidos tiene un limite de 50 items. Debes descartar "+((this.selected.length + this.added.length)-50)+' pedidos.', {
              title: `Alerta`,
              variant: 'warning',
              solid: true,
              toaster: 'b-toaster-bottom-center'
          });
          return;
        }
        this.selected.forEach((elem) => {
          if(!this.added.find(e => e.id == elem.id)){
            this.added.push({
              order_reference: elem.order_reference,
              id: elem.id,
              store_id: elem.store_id,
            })
          }
        });
      }
    },
    openDialogCambiarEstadoMasivo(){
      if(this.added.length > 0){
        this.$emit('openDialogCambiarEstadoMasivo', this.added );
      }
    },
    handleCambioEstadoMasivoSuccess(mensaje){
      this.added = [];
      this.$bvToast.toast(mensaje, {
        title: `Información`,
        variant: "success",
        solid: true,
        toaster: "b-toaster-bottom-center",
      });
      this.fetchDataPaginated();
    },
    limpiarFiltros() {
      this.search = "";
      this.dateRangeText = "";
      this.selectOrderState = "Todos";
      this.selectTypeList = "Todos";
      this.selectWarehouses = "Todos";
      this.fetchDataPaginated();
    },
    desactivaKeydown() {
      console.log("disable keydown");
    },
    fetchDataPaginated(params = null) {
        this.dataTableLoading = true;
        var vm = this;
        const sortBy = "id";
        const sortDesc = true;
        const page = this.page;
        const itemsPerPage = this.selectItemsPerPage;
        const search = this.search;
        const filterState = this.orderStateList.find(e => e.name == this.selectOrderState) ? this.orderStateList.find(e => e.name == this.selectOrderState).id : null;
        const filterWarehouse = this.warehousesList.find(e => e.name == this.selectWarehouses) ? this.warehousesList.find(e => e.name == this.selectWarehouses).id : null;
        const filterStore = this.storesList.find(e => e.name == this.selectStores) ? this.storesList.find(e => e.name == this.selectStores).id : null;
        const filterType = this.selectTypeList != 'Todos' && this.selectTypeList ? this.selectTypeList : null;
        const startDate = !(this.dateRangeText.split(' ~ ').length == 2) ? null : this.dateRangeText.split(' ~ ')[0];
        const endDate = !(this.dateRangeText.split(' ~ ').length == 2) ? null : this.dateRangeText.split(' ~ ')[1];
        this.axios({
        url: "orders/paginated/json/storegroup",
        method: "GET",
        params: {
          page,
          perPage: itemsPerPage,
          sortBy: sortBy,
          sortDesc: sortDesc ? 1 : 0,
          search,
          filterState,
          filterType,
          filterWarehouse,
          filterStore,
          startDate,
          endDate
        },
      })
        .then((response) => {
          let arreglo = [];
          let arrStores = [];
          if (response.data.data instanceof Object) {
            Object.keys(response.data.data).forEach((key) => {
              arreglo.push(response.data.data[key]);
              if (!arrStores.find(e => e.id == response.data.data[key].store_id)) {
                console.log(response.data.data[key].store_id);
                arrStores.push({
                  id: response.data.data[key].store_id,
                  name: response.data.data[key].store,
                });
              }
            });
            if (vm.storesList.length === 0){
              vm.storesList = arrStores;
              vm.storesList.unshift({ id: null, name: "Todos"});
            }
            
            vm.tableData = arreglo;
          } else {
            vm.tableData = response.data.data.map((e) => e);
          }
          vm.totalPages = response.data.last_page;
          vm.dataTableLoading = false;
          vm.selected = [];
        })
        .catch((error) => {
          console.log(error);
          vm.dataTableLoading = false;
        });
    },
    fetchOrdersStates() {
        var vm = this;

        this.axios({
            url: "orders/order_states/data_select_input",
            method: "GET",
        })
        .then((response) => {
          vm.orderStateList = response.data.data;
          vm.orderStateList.unshift({ id: null, name: "Todos", order: null });
        })
        .catch((error) => {
          console.log(error);
          vm.dataTableLoading = false;
        });
    },
    fetchWarehouses() {
        var vm = this;
        this.axios({
            url: "orders/warehouses/data_select_input",
            method: "GET",
        })
        .then((response) => {
          vm.warehousesList = response.data.data;
          vm.warehousesList.unshift({ id: null, name: "Todos", order: null });
        })
        .catch((error) => {
          console.log(error);
          vm.dataTableLoading = false;
        });
    },
    verOrden(orden_id) {
      this.$emit("openDialogVerOrden", orden_id);
    },
    cambiarEstado(datos) {
      this.$emit("openDialogCambiarEstadoOrden", datos);
      console.log(datos);
    },
    abrirMensajeSuccess(mensaje) {
      this.$bvToast.toast(mensaje, {
        title: `Información`,
        variant: "success",
        solid: true,
        toaster: "b-toaster-bottom-center",
      });
      this.fetchDataPaginated();
    },
    generarEtiqueta(datos) {
      this.$emit("openDialogSeleccionarCourier", datos);
    },
    filtroRangoFechas(value) {
      if (!this.dateRangeText || !this.dates.length) {
        return true;
      } else {
        if (
          this.$moment(value).isSameOrAfter(
            this.$moment(this.dates[0])
          ) &&
          this.$moment(value).isSameOrBefore(
            this.$moment(this.dates[1])
          )
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    formatDates(dateOne, dateTwo) {
      let formattedDates = "";
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat);
      }
      if (dateTwo) {
        formattedDates += " - " + format(dateTwo, this.dateFormat);
      }
      return formattedDates;
    },
    softDelete(item) {
      if (confirm("Está seguro?")) {
        var vm = this;
        this.axios({
          url: "orders/ref/" + item.order_reference,
          method: "DELETE",
        })
          .then((response) => {
            vm.abrirMensajeSuccess("Pedido archivado correctamente");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    formatearRango() {
      if (!this.dates.length) {
        this.dateRangeText = "";
      } else {
        this.dateRangeText = this.dates.join(" ~ ");
      }
      this.fetchDataPaginated();
    },
    limpiarRango() {
      this.dates = [];
    },
    getShippingLink(url)
    {
      window.open(url, '_blank');
      /*this.axios({
        url: 'shipment/shipping_link?order_reference='+item.order_reference+'&store='+item.store_id+'&source='+item.source_id,
      }).then( response => {
        window.open(response.data, '_blank');
      })*/
    },
    getStakOmsTrackingLink(item)
    {
      this.axios({
        url: 'orders/'+item.id+'/generate/tracking',
      }).then( response => {
        window.open(response.data, '_blank');
      })
    },
    exportExcelAll() {
      var vm = this;
      this.axios({
        url: "orders/json",
        method: "GET",
      })
        .then((response) => {
          let headers = [
            "store",
            "id",
            "type",
            "reference",
            "tracking",
            "marketplace",
            "date",
            "estado",
            "sku",
            "name",
            "quantity",
            "total",
          ];
          let mapeado = [];
          let datos = response.data.data;
          datos.forEach((order) => {
            if (order.order_details.length) {
              order.order_details.forEach((product) => {
                mapeado.push({
                  store: order.store,
                  id: order.id,
                  type: order.order_type,
                  reference: order.order_reference,
                  tracking: order.shipping_number,
                  marketplace: order.source,
                  date: order.source_date,
                  estado: order.name_order_state,
                  sku: product.sku,
                  name: product.product,
                  quantity: product.quantity,
                  total: product.total,
                });
              });
            }
          });
          vm.axios({
            url: "excel/from_array",
            method: "POST",
            data: {
              data: mapeado,
              cabeceras: headers,
            },
            responseType: "blob",
          })
            .then((response) => {
              const newBlob = new Blob([response.data], {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
              });
              const data = window.URL.createObjectURL(newBlob);
              window.open(data, "_blank");
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportExcel() {
      var vm = this;
      var headers = [
        "id",
        "type",
        "reference",
        "tracking",
        "marketplace",
        "shipping",
        "date",
        "estado",
        "sku",
        "name",
        "quantity",
        "total",
      ];
      var mapeado = [];
      this.tableData.forEach((order) => {
        if (order.order_details.length) {
          order.order_details.forEach((product) => {
            mapeado.push({
              id: order.id,
              type: order.order_type,
              reference: order.order_reference,
              tracking: order.shipping_number,
              marketplace: order.source,
              shipping: order.shipping_number,
              date: order.source_date,
              estado: order.name_order_state,
              sku: product.sku,
              name: product.product,
              quantity: product.quantity,
              total: product.total,
            });
          });
        }
      });
      this.axios({
        url: "excel/from_array",
        method: "POST",
        data: {
          data: mapeado,
          cabeceras: headers,
        },
        responseType: "blob",
      })
        .then((response) => {
          const newBlob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          const data = window.URL.createObjectURL(newBlob);
          window.open(data, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    headers() {
      return [
        { text: "Tienda", value: "store" },
        { text: "# de orden", align: "left", sortable: false, value: "id", width: '100px' },
        { text: "Referencia", value: "order_reference" /*, filter: v => (v+'').toLowerCase().includes(this['search'].toLowerCase())*/,},
        { text: "Tipo", value: "order_type_code",
          /*
          filter: (v) => {
            return this["selectTypeList"] == "Todos"
              ? true
              : v == this["selectTypeList"];
          },
          */
        },
        // { text: "Email", value: "email" },
        { text: "Origen", value: "source" },
        { text: "Tracking", value: "shipping_link"},
        {  text: "Fecha", value: "created_at",
          /*
          filter: (v) => this["filtroRangoFechas"](v),
          */
        },
        { text: "Estado", value: "name_order_state",
          /*
          filter: (v) => {
            return this["selectOrderState"] == "Todos"
              ? true
              : v == this["selectOrderState"];
          },
          */
        },
        { text: "Acciones", value: "acciones", sortable: false },
      ];
    },
  },
};
</script>

<style>
.dashboard_ordenes #icono-color-gris::before,
.dashboard_ordenes .icon-warehouses:before{
  color: #8c8c8c !important;
}
.blue_tag{
  color: #5867dd !important;
}

</style>