<template>
<v-app>
    <v-dialog v-model="showDialog" max-width="1000px">
        <v-card v-if="showDialog">
            <v-card-title>
                <span>Generar etiqueta a Pedido #{{orden_id}}</span>
            </v-card-title>
            <v-card-text>
                <div class="row">
                    <div class="col">
                        <v-select
                            v-model="courier"
                            :items="couriersList"
                            :rules="[v => !!v || 'Debes elegir un courier']"
                            label="Seleccionar courier:"
                            required
                        ></v-select>
                    </div>
                </div>
                
                
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    @click="showDialog = false"
                    >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    @click="sendForm"
                    :disabled="!courier"
                    >
                    Generar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            orden_id: undefined,
            store_id: undefined,
            courier: undefined,
            couriersList: []
        }
    },
    created(){
        this.$parent.$on('openDialogSeleccionarCourier', (pedido) => {
            this.orden_id = pedido.id;
            this.store_id = pedido.store_id;
            this.fetchData();
        });
    },
    methods: {
        sendForm(){
            var vm = this;
            this.axios({
                url: 'shippings/create',
                method: 'POST',
                data: {
                    order_id: this.orden_id,
                    courier_name: this.courier
                }
            }).then( () => {
                vm.$emit('success', "Etiqueta generada correctamente");
                vm.showDialog = false;
                
            }).catch( () => {
                //console.log(error);
            });
        },
        fetchData(){
            var vm = this;
            this.axios({
                url: 'shippings/couriers/data_select_input/'+this.store_id,
                method: 'GET'
            }).then( response => {
                vm.couriersList = response.data;
                vm.showDialog = true; 
            }).catch( (error) => {
                console.log(error);
            });
        }
    }
}
</script>