<template>
<v-app>
    <v-dialog v-model="showDialog" max-width="1000px">
        <v-card v-if="showDialog">
            <v-card-title>
                <span>Pedido #{{orden_id}}</span>
            </v-card-title>
            <v-card-text>
                <div class="row">
                    <div class="col">
                        <div class="kt-portlet">
                            <div class="kt-portlet__body  kt-portlet__body--fit">
                                <div class="row row-no-padding row-col-separator-xl">
                                    <div class="col-md-12 col-lg-6 col-xl-3">
                                        <div class="kt-widget24">
                                            <div class="kt-widget24__details">
                                                <div class="kt-widget24__info">
                                                    <h4 class="kt-widget24__title">
                                                        Nro Pedido
                                                    </h4>
                                                    <span class="kt-widget24__desc">
                                                        Interno
                                                    </span>
                                                </div>
                                                <span class="kt-widget24__stats kt-font-brand">
                                                    {{orden_id}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-6 col-xl-3">
                                        <div class="kt-widget24">
                                            <div class="kt-widget24__details">
                                                <div class="kt-widget24__info">
                                                    <h4 class="kt-widget24__title">
                                                        Referencia
                                                    </h4>
                                                    <span class="kt-widget24__desc">
                                                        Fuente
                                                    </span>
                                                </div>
                                                <span class="kt-widget24__stats kt-font-warning">
                                                    {{order.order_reference}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-6 col-xl-3">
                                        <div class="kt-widget24">
                                            <div class="kt-widget24__details">
                                                <div class="kt-widget24__info">
                                                    <h4 class="kt-widget24__title">
                                                        Fuente
                                                    </h4>
                                                    <span class="kt-widget24__desc">
                                                        Tienda / Marketplace
                                                    </span>
                                                </div>
                                                <span class="kt-widget24__stats kt-font-danger">
                                                    {{source.name}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-6 col-xl-3">
                                        <div class="kt-widget24">
                                            <div class="kt-widget24__details">
                                                <div class="kt-widget24__info">
                                                    <h4 class="kt-widget24__title">
                                                        Fecha
                                                    </h4>
                                                    <span class="kt-widget24__desc">
                                                        Fuente
                                                    </span>
                                                </div>
                                                <span class="kt-widget24__stats kt-font-success">
                                                    {{$moment(order.source_date).format('DD-MM-YYYY HH:mm')}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                            <div class="col">
                                <div class="kt-portlet">
                                    <div class="kt-portlet__body kt-portlet__body--fit">
                                        <div :class="clasesDivComputado" ref="divComputado" >
                                            
                                                <div class="kt-widget1" :style="{padding: '0.9rem', background: orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_OMS') ? '#5d78ff':''}">
                                                    <div class="kt-widget1__item">
                                                        <div class="kt-widget1__info">
                                                            <h3 :class="['kt-widget1__title', orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_OMS') ? 'kt-shape-font-color-1':'']">Ingresado</h3>
                                                            <span class="kt-widget1__desc kt-shape-font-color-1">{{orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_OMS') ? $moment(orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_OMS').created_at).format('DD-MM-YYYY HH:mm') :''}}</span>
                                                        </div>
                                                        <span :class="['kt-widget1__number', orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_OMS') ? 'kt-shape-font-color-1':'']" style="font-size: 3em;"><i class="la la-check"></i></span>
                                                    </div>
                                                </div>
                                            
                                            
                                                <div class="kt-widget1" :style="{padding: '0.9rem', background: orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_PACKAGING') ? '#5d78ff':''}">
                                                    <div class="kt-widget1__item">
                                                        <div class="kt-widget1__info">
                                                            <h3 :class="['kt-widget1__title', orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_PACKAGING') ? 'kt-shape-font-color-1':'']">Preparando</h3>
                                                            <span class="kt-widget1__desc  kt-shape-font-color-1">{{orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_PACKAGING') ? $moment(orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_PACKAGING').created_at).format('DD-MM-YYYY HH:mm') :''}}</span>
                                                        </div>
                                                        <span :class="['kt-widget1__number', orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_PACKAGING') ? 'kt-shape-font-color-1':'']" style="font-size: 3em;"><i class="la la-dropbox"></i></span>
                                                    </div>
                                                </div>
                                            
                                            
                                                <div class="kt-widget1" :style="{padding: '0.9rem', background: orderHistory.find(e => e.order_state.type == 'DEFAULT_OUT_SHIPMENT') ? '#5d78ff':''}">
                                                    <div class="kt-widget1__item">
                                                        <div class="kt-widget1__info">
                                                            <h3 :class="['kt-widget1__title', orderHistory.find(e => e.order_state.type == 'DEFAULT_OUT_SHIPMENT') ? 'kt-shape-font-color-1':'']">Listo para Enviar</h3>
                                                            <span class="kt-widget1__desc  kt-shape-font-color-1">{{orderHistory.find(e => e.order_state.type == 'DEFAULT_OUT_SHIPMENT') ? $moment(orderHistory.find(e => e.order_state.type == 'DEFAULT_OUT_SHIPMENT').created_at).format('DD-MM-YYYY HH:mm') :''}}</span>
                                                        </div>
                                                        <span :class="['kt-widget1__number', orderHistory.find(e => e.order_state.type == 'DEFAULT_OUT_SHIPMENT') ? 'kt-shape-font-color-1':'']" style="font-size: 3em;"><i class="la la-archive"></i></span>
                                                    </div>
                                                </div>
                                            
                                            
                                                <div class="kt-widget1" :style="{padding: '0.9rem', background: orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CARRIER') ? '#5d78ff':''}">
                                                    <div class="kt-widget1__item">
                                                        <div class="kt-widget1__info">
                                                            <h3 :class="['kt-widget1__title', orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CARRIER') ? 'kt-shape-font-color-1':'']">Entregado a Transporte</h3>
                                                            <span class="kt-widget1__desc  kt-shape-font-color-1">{{orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CARRIER') ? $moment(orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CARRIER').created_at).format('DD-MM-YYYY HH:mm') :''}}</span>
                                                        </div>
                                                        <span :class="['kt-widget1__number', orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CARRIER') ? 'kt-shape-font-color-1':'']" style="font-size: 3em;"><i class="la la-exchange"></i></span>
                                                    </div>
                                                </div>
                                            
                                            
                                                <div class="kt-widget1" :style="{padding: '0.9rem', background: orderHistory.find(e => e.order_state.type == 'DEFAULT_ON_CARRIER') ? '#5d78ff':''}">
                                                    <div class="kt-widget1__item">
                                                        <div class="kt-widget1__info">
                                                            <h3 :class="['kt-widget1__title', orderHistory.find(e => e.order_state.type == 'DEFAULT_ON_CARRIER') ? 'kt-shape-font-color-1':'']">En Ruta</h3>
                                                            <span class="kt-widget1__desc  kt-shape-font-color-1">{{orderHistory.find(e => e.order_state.type == 'DEFAULT_ON_CARRIER') ? $moment(orderHistory.find(e => e.order_state.type == 'DEFAULT_ON_CARRIER').created_at).format('DD-MM-YYYY HH:mm') :''}}</span>
                                                        </div>
                                                        <span :class="['kt-widget1__number', orderHistory.find(e => e.order_state.type == 'DEFAULT_ON_CARRIER') ? 'kt-shape-font-color-1':'']" style="font-size: 3em;"><i class="la la-truck"></i></span>
                                                    </div>
                                                </div>
                                            
                                            
                                                <div class="kt-widget1" :style="{padding: '0.9rem', background: orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CUSTOMER') ? '#5d78ff':''}">
                                                    <div class="kt-widget1__item">
                                                        <div class="kt-widget1__info">
                                                            <h3 :class="['kt-widget1__title', orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CUSTOMER') ? 'kt-shape-font-color-1':'']">Entregado</h3>
                                                            <span class="kt-widget1__desc  kt-shape-font-color-1">{{orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CUSTOMER') ? $moment(orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CUSTOMER').created_at).format('DD-MM-YYYY HH:mm') :''}}</span>
                                                        </div>
                                                        <span :class="['kt-widget1__number', orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_CUSTOMER') ? 'kt-shape-font-color-1':'']" style="font-size: 3em;"><i class="la la-flag-checkered"></i></span>
                                                    </div>
                                                </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    @click="showDialog = false"
                    >
                    OK
                </v-btn>
                <!--<v-btn
                    color="success"
                    text
                    @click="sendForm"
                    >
                    Guardar
                </v-btn>-->
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            orden_id: undefined,
            order: undefined,
            customer: undefined,
            address: undefined,
            source: undefined,
            orderHistory: undefined,
            histories: undefined,
            orderDetail: undefined,
            statusAll: undefined,
        }
    },
    created(){
        
        this.$parent.$on('openDialogVerOrden', (orden_id) => {
            this.orden_id = orden_id;
            this.fetchData();
                
        });
    },
    methods: {
        sendForm(){
            console.log('se deberia enviar el formulario');
            var vm = this;
            this.axios({
                url: 'admin/users',
                method: 'POST',
                data: this.user
            }).then( () => {
                vm.showDialog = false;
                vm.$emit('success');
            }).catch( error => {
                console.log(error);
            });
        },
        fetchData(){
            var vm = this;
            this.axios({
                url: 'orders/'+this.orden_id
            }).then( response => {
                vm.order = response.data.order;
                vm.customer = response.data.customer;
                vm.address = response.data.address;
                vm.source = response.data.source;
                vm.orderHistory = response.data.orderHistory;
                vm.histories = response.data.histories;
                vm.orderDetail = response.data.orderDetail;
                vm.statusAll = response.data.statusAll;
                vm.showDialog = true; 
            }).catch( error => {
                console.log(error);
            });
        }
    },
    computed: {
        clasesDivComputado(){
            
            return (['d-flex flex-column']);
        }
    },
    mounted(){
        console.log('$refs.divComputado',this.$refs.divComputado);
    }
}
</script>